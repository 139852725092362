<template>
    <div class="container">

        <div class="row">
            <div>
                <h3>Playlist laden</h3><br/>
                <p class="warning">Achtung: Dadurch wird die aktuelle Playlist komplett überschrieben! Alle Votes und
                    Tracks gehen dabei verloren.</p>

                <div v-if="importing">
                  Laden... {{ importProgress }} / {{ importTotal }} <br />
                  <progress :value="importProgress" :max="importTotal"></progress>

                </div>

                <table class="table table-hover" v-if="!dataLoading && !importing">
                    <thead class="thead-dark2">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Anzahl Songs</th>
                        <th scope="col">Playlist laden</th>
                    </tr>

                    </thead>
                    <tbody>
                    <tr v-for="list in playlists.playlists.items" :key="list.id">
                        <td>{{ list.name }}</td>
                        <td>{{ list.tracks.total }}</td>
                        <td>
                            <a class="btn btn-secondary btn-sm" @click="loadPlaylist(list.id)" v-if="!importing">
                                <i class="fas fa-check"></i>
                            </a>
                            <span v-else-if="importing === list.id">Laden...</span>
                        </td>
                    </tr>
                    </tbody>

                    <tfoot>
                    <td>
                        <a href="#" @click.prevent="prev()" v-if="offset > 0">Vorherige 20</a>
                    </td>
                    <td colspan="2" align="right">
                        <a href="#" @click.prevent="next()">Nächste 20</a>
                    </td>
                    </tfoot>
                </table>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Home',
        components: {},
        mounted: async function () {
            this.loadData()
        },
        watch: {},
        methods: {
            loadData: async function () {
                this.dataLoading = true;
                const response = await axios.get('/host/my-playlists?offset=' + this.offset + '&limit=' + this.limit);

                this.playlists = response.data;
                this.dataLoading = false;
            },
            loadPlaylist: async function (id) {
                this.importing = true;
                const response = await axios.get('/host/set-playlist?id=' + id);

                if (response.data.total) {
                  this.importTotal = response.data.total;
                  this.importProgress = 0;
                  this.perPage = response.data.perPage;
                  const self = this;

                  function loadNextChunk() {
                    axios.get('/host/set-playlist?id=' + id + '&offset=' + self.importProgress).then(response => {
                      self.importProgress += self.perPage;

                      if (self.importProgress >= self.importTotal) {
                        self.importing = false;
                        alert('Fertig importiert...');
                      }
                      else {
                        loadNextChunk();
                      }
                    }).catch(error => {
                      console.log(error);
                      self.importProgress += self.perPage;

                      if (self.importProgress >= self.importTotal) {
                        self.importing = false;
                        alert('Fertig importiert...');
                      }
                      else {
                        loadNextChunk();
                      }
                    })
                  }

                  loadNextChunk();
                }
            },
            next: function () {
                this.offset += this.limit;

                this.loadData();
            },
            prev: function () {
                this.offset -= this.limit;

                if (this.offset <= 0) {
                    this.offset = 0;
                }

                this.loadData();
            }
        },
        computed: {},
        data: function () {
            return {
                playlists: {},
                offset: 0,
                limit: 20,
                dataLoading: true,
                importing: false,
                importTotal: null,
                perPage: 25,
                importProgress: 0
            }
        }
    }
</script>
<style lang="stylus" scoped>

    .table-hover {
        width: 100%;
    }

    .table-hover tfoot a {
        color: black;
    }

    .table-hover tbody tr:hover td {
        background-color: rgba(69, 153, 55, 0.2);
    }

    .thead-dark2 {
        background-color: rgba(69, 153, 55, 0.2);
    }

    .c-grid-block {
        background-color: rgba(126, 186, 118, 0.1);
        color: #000000;
        /* box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.1), 0 0.3rem 0.1rem 0 rgba(0, 0, 0, 0.05);
        */
    }

</style>